import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventLoggerService } from '@services/event-logger-service';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { ApiClientConstant } from '@cureskin/api-client/src/constant/api-client.constant';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'user-regimen-services',
  templateUrl: './user-regimen-services.html',
  standalone: false,
})
export class UserRegimenServicesComponent {
  regimen: any;
  user: any;
  loading: boolean = true;
  offerTimer: any = {};
  allocatedDoctor: any;
  byPassLocalFetch: boolean = false;
  products: any = [];

  constructor(private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    private conn: ConnectionService,
    private router: Router,
    private broadcast: BroadcastService,
    private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    const regimenClass = this.route.snapshot.queryParams.class;
    const { regimenId }: any = this.route.snapshot.queryParams;
    this.byPassLocalFetch = true;
    this.user = await this.conn.getActingUser();
    const regimens = await this.conn.fetchRegimens(regimenId, true, this.byPassLocalFetch);
    this.regimen = regimens.find((each: any): boolean => each.class === regimenClass);
    this.regimen.totalMRP = this.regimen?.fixedPriceMRP;
    this.regimen.totalSP = this.regimen?.fixedPrice;
    this.regimen.discount = Math.floor(((this.regimen.totalMRP - this.regimen.totalSP) * 100) / this.regimen.totalMRP);

    if (this.user.get('allocatedDoctor')) {
      this.allocatedDoctor = await this.conn.findUserByObjectId(this.user.get('allocatedDoctor').id);
    }

    const uniqueProducts = {};
    [...this.regimen.morning, ...this.regimen.night].forEach((item: any): void => {
      if (item.product.price) uniqueProducts[item.product.objectId] = item;
    });
    this.products = Object.values(uniqueProducts);

    this.loading = false;
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'regimen-services' }));
  }

  buyRegimen(): void {
    this.eventLogger.trackInParse('BUY_NOW', ApiClientConstant.Event.Type.ORDER);
    this.eventLogger.trackEvent('BUY_NOW_CLICKED');
    this.eventLogger.trackInFirebaseAndBranch('BUY_NOW_CLICKED');
    if (this.regimen.class === this.appConfig.Shared.Regimen.Class.HAIR) {
      this.eventLogger.trackEvent('BUY_NOW_CLICKED_HAIR');
      this.eventLogger.trackInFirebaseAndBranch('BUY_NOW_CLICKED_HAIR');
    }
    this.router.navigate(['/user/checkout'],
      { queryParams: { type: 'REGIMEN', regimenId: this.regimen.regimenId }, replaceUrl: true });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
