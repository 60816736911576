<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-fixed tw-right-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" *ngIf="paid" (click)="goToCart()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'shopping_cart'"></cs-icon><span class="tw-absolute tw-top-[2px] tw-right-[18%] tw-bg-black tw-font-body tw-font-bold tw-h-[14px] tw-text-200 tw-text-red-400">{{toolbarIcons[0]?.cartValue}}</span>
</div>
<div class="tw-block tw-overflow-hidden tw-h-full tw-w-full tw-font-body">
  <div class="tw-flex tw-flex-col tw-h-full">
  <div class="tw-flex-1 tw-relative tw-overflow-y-auto tw-overflow-x-hidden" #articleBlock="" [ngClass]="{ 'tw-text-300': article && article.get('cssLink') }">
    <loading class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" *ngIf="!innerHTML"></loading>
    <link *ngIf="cssLink" itemprop="url" rel="stylesheet" [href]="cssLink" /><img *ngIf="articleHeaderImage" [src]="articleHeaderImage" />
    <div *ngIf="innerHTML" (click)="navigationClick($event)" [innerHTML]="innerHTML"></div>
  </div>
  <footer class="tw-flex-none tw-shadow-z1" *ngIf="user && innerHTML && clickToAction && clickToAction === appConfig.Shared.ArticleData.clickToAction.BUY_PRODUCT">
    <div class="tw-flex tw-item-center tw-justify-center tw-py-2 tw-bg-yellow-100" *ngIf="totalMRP !== totalSP && +discount >= 5">
    <p class="tw-font-body tw-text-200 tw-text-black tw-flex tw-items-center tw-gap-1 tw-my-0">
      <cs-icon class="tw-h-5 tw-w-5 tw-text-green-400" [iconName]="'sale'"></cs-icon><span i18n="@@discountAppliedForProducts">
      {{discount}}% discount applied</span><span i18n="@@amountSaved">(₹{{totalMRP - totalSP}} saved)</span>
    </p>
    </div>
    <div class="tw-flex tw-gap-4 tw-items-center tw-p-5">
    <div class="tw-flex-none" *ngIf="totalMRP !== totalSP">
      <del class="tw-font-body tw-font-bold tw-text-600 tw-text-gray-900">₹{{totalMRP}}</del><span class="tw-font-body tw-font-bold tw-text-600 tw-text-orange-500">&nbsp;₹{{totalSP}}</span>
    </div>
    <div class="tw-flex-none" *ngIf="totalMRP === totalSP">
      <span class="tw-font-body tw-font-bold tw-text-600 tw-text-green-400">₹{{totalSP}}</span>
    </div>
    <div class="button tw-flex-auto">
      <cs-button [class]="'primary w100 shine tw-gap-2 tw-items-center tw-leading-none'" (callback)="buttonAction()"><cs-button-text class="tw-font-body tw-font-bold tw-text-300" i18n="@@addToCart">Add to Cart</cs-button-text><cs-icon class="tw-h-7 tw-w-7 tw-text-white" [iconName]="'chevron_right'"></cs-icon></cs-button>
    </div>
    </div>
  </footer>
  <div class="tw-px-4 tw-py-5 tw-shadow-z1" *ngIf="user && innerHTML && clickToAction && clickToAction !== appConfig.Shared.ArticleData.clickToAction.NONE && clickToAction !== appConfig.Shared.ArticleData.clickToAction.BUY_PRODUCT">
    <div class="tw-w-full" *ngIf="clickToAction !== appConfig.Shared.ArticleData.clickToAction.BUY_PRODUCT">
    <cs-button [class]="'primary w100'" (callback)="buttonAction()" *ngIf="clickToAction === appConfig.Shared.ArticleData.clickToAction.BUY_REGIMEN"><cs-button-text i18n="@@checkTreatment">Check Your Treatment Kit</cs-button-text></cs-button><cs-button [class]="'primary w100'" (callback)="buttonAction()" *ngIf="clickToAction === appConfig.Shared.ArticleData.clickToAction.SHARE"><cs-button-text i18n="@@share">Share</cs-button-text></cs-button><cs-button [class]="'primary w100'" (callback)="buttonAction()" *ngIf="clickToAction === appConfig.Shared.ArticleData.clickToAction.REFER"><cs-button-text i18n="@@referAndEarn">Refer & Earn</cs-button-text></cs-button>
    </div>
  </div>
  </div>
</div>