import { Component, Input } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';
import { AppConfig } from '../../../../app.config';

export enum LastLevelType {
  DIET = 'DIET',
  REFER = 'REFER',
  QUIZ = 'QUIZ'
}

interface BannerImages {
  banner: string;
  icon: string;
  bgColor: string;
}

@Component({
  selector: 'last-level-banner',
  templateUrl: './last-level-banner.html',
  imports: [
    NgIf,
    NgClass,
  ],
})
export class LastLevelBannerComponent {
  @Input() type!: string;
  @Input() user!: any;

  readonly bannerImages: Record<LastLevelType, BannerImages> = {
    [LastLevelType.DIET]: {
      banner: '/assets/images/dietBannerImage.png',
      icon: '/assets/images/dietBannerIcon.gif',
      bgColor: 'tw-bg-yellow-200',
    },
    [LastLevelType.REFER]: {
      banner: '/assets/images/referBannerImage.png',
      icon: '/assets/images/referBannerIcon.gif',
      bgColor: 'tw-bg-blue-200',
    },
    [LastLevelType.QUIZ]: {
      banner: '/assets/images/quizBannerImage.gif',
      icon: '/assets/images/quizBannerIcon.gif',
      bgColor: 'tw-bg-teal-200',
    },
  };

  constructor(public appConfig: AppConfig) {
  }
}
