import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { DirectivesModule } from '@directives/directives.module';
import { PopUpModule } from '@components/pop-up';
import { ProductCarouselModule } from '@shared/product-carousel/product-carousel.module';
import { SwiperModule } from 'swiper/angular';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { CureskinCashSheetModule } from '@shared/bottom-sheet-layouts/cureskin-cash-sheet/cureskin-cash.module';
import { ShopCarouselsModule } from '@shared/shop-carousels/shop-carousels.module';
import { LoaderShopModule } from '@shared/loader-shop/loader-shop.module';
import { DiscontinuedPopupModule } from '@shared/discontinued-popup/discontinued-popup.module';
import { CartDetailStripModule } from '@shared/cart-detail-strip/cart-detail-strip.module';
import { AovSaleModule } from '@shared/aov-sale/aov-sale.module';
import { ShopBannersModule } from '@shared/shop-order-banners/shop-order-banners.module';
import { BoostYourRegimenModule } from '@shared/boost-your-regimen/boost-your-regimen.module';
import { UserProfileModule } from '../../../user-profile/user-profile.module';
import { BannerScrollbarModule } from '../user-home/banner-scrollbar/banner-scrollbar.module';
import { DietBannerModule } from '../user-home/diet-banner/diet-banner.module';
import { OrderDetailBannerModule } from '../user-home/order-details-banner/order-details-banner.module';
import { UserOrderBannerModule } from '../user-home/user-order-banner/user-order-banner.module';
import { CrossRegimenBannerModule } from '../user-home/cross-regimen-banner/cross-regimen-banner.module';
import { ShopModule } from '../../../shop/shop.module';
import { UserShopComponent } from './user-shop.component';
import { AovBannerComponent } from '../../../cart/aov-banner/app-aov-banner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    ToolbarModule,
    ButtonModule,
    PopUpModule,
    UserProfileModule,
    ProductCarouselModule,
    BannerScrollbarModule,
    DietBannerModule,
    SwiperModule,
    OrderDetailBannerModule,
    UserOrderBannerModule,
    LoadingModule,
    CureskinCashSheetModule,
    CrossRegimenBannerModule,
    CsIconModule,
    ShopModule,
    ShopCarouselsModule,
    LoaderShopModule,
    DiscontinuedPopupModule,
    CartDetailStripModule,
    AovSaleModule,
    ShopBannersModule,
    BoostYourRegimenModule,
    AovBannerComponent,
  ],
  declarations: [UserShopComponent],
  exports: [UserShopComponent],
})
export class UserShopModule { }
